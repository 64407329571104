import { Controller } from "@hotwired/stimulus";
import { showOverlay, hideOverlay } from "../plugins/overlay";
import {
  openPriceNotReached,
  closePriceNotReached,
} from "../plugins/priceNotReached";

// Connects to data-controller="basket"
export default class extends Controller {
  static targets = ["modal", "trigger"];

  open() {
    if (!this.modalTarget.classList.contains("slide-in-from-right")) {
      this.modalTarget.classList.add("slide-in-from-right");
      this.modalTarget.classList.remove("slide-out-on-right");
    }

    // after 0.5s (time of animation, set the focus. not before or it breaks bad)
    setTimeout(
      () => this.modalTarget.querySelector("[tabindex]")?.focus(),
      500,
    );
  }

  close() {
    if (this.modalTarget.classList.contains("slide-in-from-right")) {
      this.modalTarget.classList.remove("slide-in-from-right");
      this.modalTarget.classList.add("slide-out-on-right");
      // after 0.5s , remove slide-out-on-right as well to avoid glitches due to the
      // "click outside" behavior triggering a close. If we don't remove it,
      // any further click after a close will do the slide-out-on-right animation.
      setTimeout(
        () => this.modalTarget.classList.remove("slide-out-on-right"),
        500,
      );
    }
  }

  showOverlay() {
    showOverlay(this.modalTarget);
  }

  hideOverlay() {
    hideOverlay(this.modalTarget);
  }

  openWithOverlay() {
    this.open();
    this.showOverlay();
  }

  openPriceNotReached() {
    openPriceNotReached();
  }

  closePriceNotReached() {
    closePriceNotReached();
  }

  tryClosing(event) {
    // close on ESC key
    if (event.type === "keydown" && event.key === "Escape") return this.close();

    // Do not close if clicked:
    // - on the basket button itself (or its child span)
    // - on the basket itself
    if (
      this.hasTriggerTarget &&
      this.hasModalTarget &&
      !this.triggerTarget.contains(event.target) &&
      !this.modalTarget.contains(event.target)
    )
      return this.close();
  }
}
