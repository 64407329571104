import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mobile-navbar"
export default class extends Controller {
  static targets = ["navbar", "menu", "zip"];

  open() {
    if (!this.navbarTarget.classList.contains("slide-in-from-left")) {
      this.navbarTarget.classList.add("slide-in-from-left");
      this.navbarTarget.classList.remove("slide-out-on-left");
    }
  }

  close() {
    if (this.navbarTarget.classList.contains("slide-in-from-left")) {
      this.navbarTarget.classList.remove("slide-in-from-left");
      this.navbarTarget.classList.add("slide-out-on-left");
      // after 0.5s , remove slide-out-on-left as well to avoid glitches due to the
      // "click outside" behavior triggering a close. If we don't remove it,
      // any further click after a close will do the slide-out-on-left animation.
      setTimeout(
        () => this.navbarTarget.classList.remove("slide-out-on-left"),
        500,
      );
    }
  }

  tryClosing(event) {
    // close on ESC key
    if (event.type === "keydown" && event.key === "Escape") return this.close();

    // close on clicking the zip code area in the sidebar
    if (this.hasZipTarget && this.zipTarget.contains(event.target))
      return this.close();

    // Do not close if clicked:
    // - on the menu button itself (or its child span)
    // - on the sidebar itself apart from the zip code area
    if (
      this.hasMenuTarget &&
      this.hasNavbarTarget &&
      !this.menuTarget.contains(event.target) &&
      !this.navbarTarget.contains(event.target)
    )
      return this.close();
  }
}
