// https://github.com/hotwired/turbo-rails/pull/367#issuecomment-1934729149
// Still baffled you can't properly redirect and break out of a frame easily
Turbo.StreamActions.redirect_replace = function () {
  Turbo.visit(this.target, { action: "replace" });
};

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// Custom actions
Turbo.StreamActions.push_state = function () {
  const query = new URLSearchParams();

  Object.entries(JSON.parse(this.target)).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((item) => query.append(`${key}[]`, item));
    } else {
      query.append(key, val);
    }
  });

  const queryParams = query.toString();

  const url = `${window.location.pathname}${
    queryParams === "" ? "" : `?${queryParams}`
  }`;

  history.replaceState(null, "", url);
};

Turbo.StreamActions.reload = function () {
  // if the frame has a `src`, reload
  // if not but has a data-src => load that src
  // else do nothing
  document.querySelectorAll(`turbo-frame#${this.target}`).forEach((frame) => {
    if (frame.src) {
      frame.reload();
    } else if (frame.dataset.src) {
      frame.src = frame.dataset.src;
    }
  });
};
